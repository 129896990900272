#model_commentaire{

  @media screen and (min-width: 360px) and (max-width: 576px){
    img{
      width: 25px !important;
    }

    .blocReponse{
      padding-left: 0.5% !important;
      margin-right: 5px !important;
    }
  }
}