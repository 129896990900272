.detail {

  @media screen and (min-width: 360px) and (max-width: 576px) {
    .imageArticle{
      img {
        width: 80% !important;
      }
    }
  }

  .imageArticle {
    img {
      width: 730px !important;
      height: 450px !important;
    }
  }

  #detail-body{
    img {
      width: 100% !important;
    }

  }
}