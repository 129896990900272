#side-bar{
  background: #171616;
  border: 5px solid whitesmoke;
  overflow: hidden;

  .navigationSideBar{
    display: flex !important;
    flex-direction: column;
    padding-top: 5px !important;

    a{
      padding: 5px;
      text-align: center;
      color: black !important;

      &:hover{
        color: white !important;
        background: rgba(230, 41, 41, 0.82);
        border-radius: 4px;

        img{
          filter: invert(100%) !important;
        }

        i{
          color: white !important;
        }
      }
    }

    .nav-active{
      color: white !important;
      background: #e62929;
      border-radius: 4px;

      &:after{
        width: 0;
      }

      img{
        filter: invert(100%) !important;
      }
  }



  button{

    &:hover{
      background: #010124;
      color: white;
    }
  }

  .connexion{
    a{
      border-bottom: 1px solid #bdbbbb;

      &:hover{
        color: blue !important;
      }
    }
  }
  }

.side-barDisplay{
  animation: DisplaySide .4s ease-in-out;
  animation-fill-mode: forwards;

  @keyframes DisplaySide {
    0% {
      transform: translateX(200%);
    }
    100% {
      transform: translateX(35%);
    }
  }
}

.side-barHidden{
  // display: none !important;
  animation: HiddenSide .4s;
  animation-fill-mode: forwards;

  @keyframes HiddenSide {
    0% {
      transform: translateX(35%);
    }
    100% {
      transform: translateX(200%);
    }
  }
}
}