.charge-more{
    background: rgba(230, 41, 41, 0.24);
    color: white;
    cursor: pointer;
    font-size: 0.80em
}

.charge-more:hover{
    background: #e62929;
    color: white;
}

.section-title{
    color: #ffffff;
    border-top-left-radius: 10px;
    border-bottom-right-radius:  10px
}

.section-position{
    top: -12px;
    left: 20px
}

.slick-dots2{
    position: relative !important;
}

.btn-scroll{
    border: 4px solid #e62929;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2em;
    border-radius: 5px;
    background: transparent !important;
    color: #e62929;
    font-weight: bolder;
}

.btn-scroll:hover{
    background: #e62929 !important;
    color: white !important;
}

.text-red{

}

.text-red:hover{
    color: #e62929 !important;
    cursor: pointer;
}