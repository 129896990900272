.navigation{
  padding: 10px;

}

.navigation > a {
  padding: 8px 12px;
  margin: 0 5px;
  position: relative;
  color: #171616 !important;
  transition: background-color .5s, font-weight 1s;

}

.navigation > a:hover{
  color: white !important;
  background: rgba(23, 22, 22, 0.84);
  border-radius: 4px;
  font-weight: bold;
}

.navigation .nav-active::after{
  content: '';
  width: 85%;
  height: 4px;
  background: #daa00b;
  position: absolute;
  top: 30px;
  left: -35%;
  transform: translate(50%);
  border-radius: 2px;
}
