html{
  height: 100%;
}

body {
  font-family: 'Georgia', sans-serif !important;
  font-size: 0.9em !important;
}

.bi {
  margin: .25rem;
  font-size: 2.5rem;
}
.label {
  font-family: var(--bs-font-monospace);
}
.label {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: .25rem;
  font-size: .625rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a{
  color: #00235e !important;
}


.effet:hover{
  opacity: 0.7;
}

.cat{
  background: #e62929;
}
.cat:hover{
  background: rgba(230, 41, 41, 0.6);
}

.pol{
  background: #0e053d !important;
}
.pol:hover{
  background: rgba(10, 2, 54, 0.89) !important;
}
.text-pol:hover{
  color: #0e053d !important;
}
.text-pol{
  color: black !important;
}
.btn-pol{
  color: #0e053d !important;
}

.eco{
  background: #120788 !important;
}
.eco:hover{
  background: #0b0536 !important;
}
.text-eco:hover{
  color: #120788 !important;
}
.text-eco{
  color: black !important;
}
.btn-eco{
  color: #120788 !important;
}

.societe{
  background: #daa00b !important;
}
.societe:hover{
  background: #b78c12 !important;
}
.text-societe:hover{
  color: #daa00b !important;
}
.text-societe{
  color: black !important;
}
.btn-societe{
  color: #daa00b !important;
}


.sport{
  background: green !important;
}
.sport:hover{
  background: #0f2a0f !important;
}
.text-sport:hover{
  color: green !important;
}
.text-sport{
  color: black !important;
}
.btn-sport{
  color: green !important;
}



.culture{
  background: #171616 !important;
}
.culture:hover{
  background: black !important;
}
.text-culture:hover{
  color: black !important;
}
.text-culture{
  color: black !important;
}
.btn-culture{
  color: black !important;
}

.error{
  border: 2px solid red !important;
  color: #120788;
}

.text-black-40{color: rgba(0, 0, 0, 0.46) !important}

.text-black-30{color: rgba(0, 0, 0, 0.32) !important}

.center-absolute{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 6em;
  height: 3em;
  border-radius: 0.8em;
  border: 0.2em solid white;
  background: rgba(73, 72, 72, 0.76);
}

.poster-video:hover{
  .center-absolute{
    background: rgb(68, 66, 66);
  }
}