.line-footer{
    position: relative;
}

.line-footer::after{
    content: '';
    width: 35%;
    height: 4px;
    background: #e62929;
    position: absolute;
    top: 1.2em;
    left: -18%;
    transform: translate(50%);
    border-radius: 2px;
}