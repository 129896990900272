#cardComment{
    /*clip-path: polygon(100% 100%, 100% 0%, 2% 0%, 2% 9%, 0% 18%, 2% 18%, 2% 100%);*/
    border-radius: 10px;
    width: 800px;

}

.icobtn{
    transition: 0.5s;
    transform: rotateX(180deg) translateY(1px);
}

.mainPart{

}

.DisplayReponse{
    position: relative;
}

.DisplayReponse::after{
    content: '';
    width: 10%;
    background: #374d3f;
    position: absolute;
    bottom: 0;
    height: 2px;
    animation: long 400ms;
    transition: 1s;
    animation-fill-mode: forwards;
}

@keyframes long {
    from{
        width: 0%;
    }
    to{
        width: 91%;
    }
}