#Agenda_animation{
    animation: transit 10s infinite ease-in-out;
    animation-fill-mode: forwards;
    transition: 2s ease-in;
}

@keyframes transit {
    0%{
        transform: translateY(-100px);
    }
    20%{
        transform: translate(0);
    }
    90%{
        transform: translate(0);
         opacity: 1
    }
    100%{
        opacity: 0;
        transform: translateX(10%) translateY(0);
    }
}

@media screen and (min-width: 360px) and (max-width: 576px){
    #imageLogo{
        position: static !important;
    }
    #imageLogo > img{
        width: 100px;
        height: auto;
    }

    .containerLogo{
        display: flex !important;
    }

    #containerForm{
        /*max-width: 40%;*/
    }
    #containerForm > form > div{
        position: relative;
        width: 50% !important;
        margin-left: 2%;
        margin-right: 2%;
    }

    #card1 > div{
        height: auto;
    }

    #card1 > div > img{
        height: 40%;
    }
}