.register{
  height: 100vh;
  position: relative;
  // background-color: black;


  .container-register{
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    height: 80%;
    width: 35em;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: #0b453a;
    color: whitesmoke;

    form{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;

      .container-label{
        display: flex;
        flex-direction: column;
      }
    }
  }
}