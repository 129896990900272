.slide1{
    animation: sliders1 8s infinite;
    animation-fill-mode: forwards;
}

.slide2{
    animation: sliders 10s infinite;
    animation-fill-mode: forwards;

    @media screen and (min-width: 360px) and (max-width: 576px){
        max-width: 80% !important;
        max-height: 2em !important;
        overflow: hidden;
    }
}

@keyframes sliders1 {
    0%{
        transform: translateX(80%);
    }
    20%{
        transform: translateX(0%);
    }
    90%{
        transform: translateX(0%);
        opacity: 1;
    }
    100%{
        transform: translateX(10%);
        display: none;
        opacity: 0;
    }
}

@keyframes sliders {
    2%{
        transform: translateX(10%);
        overflow: hidden !important;
    }
    20%{
        transform: translateX(0%);
    }
    85%{
        transform: translateX(0%);
        opacity: 1;
    }
    100%{
        transform: translateX(10%);
        opacity: 0;
    }

}

.flash{
    animation: Flash 1s linear infinite;
    animation-delay: 2s;
}
@keyframes Flash {
    0%{
        color: #27232a;
        text-shadow: none;
    }
    60%{
        color: #27232a;
        text-shadow: none;
    }
    100%{
        color: yellow;
        text-shadow: 0 0 2px yellow, 0 0 0 yellowgreen;
    }
}